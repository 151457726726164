var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-mobile animated fadeIn" }, [
    _c(
      "div",
      { staticClass: "form content-contact-mobile" },
      [
        _vm.isFilmSingle && !_vm.isFilmSingleFree
          ? _c(
              "b-row",
              { staticClass: "mb-3" },
              [
                _c("b-col", [
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "light" },
                          on: { click: _vm.handleClickAddProject },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(21, "Add project")) +
                              "\n\t\t\t\t\t\t"
                          ),
                          _c("plus-circle", {
                            attrs: { color: "#06263E", size: 16 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.isFilmSingle && _vm.isFilmSingleFree
          ? _c(
              "b-row",
              { staticClass: "mb-3" },
              [
                _c("b-col", [
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c("subscription-dialog", {
                        attrs: {
                          variantBtn: "light",
                          icon: "fa fa-plus",
                          "btn-label": _vm.FormMSG(21, "Add project"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { staticClass: "cols-12" },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.FormMSG(1, "Type to search ..."),
                      },
                      on: { input: _vm.handleInputFilter },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c(
                          "b-input-group-text",
                          [
                            _vm.filter.length === 0
                              ? _c("search", {
                                  staticClass: "icon",
                                  attrs: { color: "#06263E", size: 16 },
                                })
                              : _c("x", {
                                  staticClass: "icon",
                                  attrs: { color: "#06263E", size: 16 },
                                  on: { click: _vm.clearFilter },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "mt-3" },
          [
            _c("b-col", [
              _c("div", { staticClass: "title-list" }, [
                _c("h2", [_vm._v(_vm._s(_vm.title))]),
              ]),
            ]),
          ],
          1
        ),
        _vm.listProjects.length === 0
          ? _c("div", { staticClass: "text-center" }, [
              _c("p", [_vm._v(_vm._s(_vm.FormMSG(3, "No result")))]),
            ])
          : _vm._e(),
        _vm._l(_vm.listProjects, function (project, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "cursor-pointer",
              class: { active: project.id === _vm.myProject.id },
              on: {
                click: function ($event) {
                  return _vm.clickRow(project)
                },
              },
            },
            [
              index > 0 ? _c("div", { staticClass: "divider-line" }) : _vm._e(),
              _c(
                "b-row",
                { staticClass: "pt-3 pb-3", class: { "mt-3": index === 0 } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-0",
                      attrs: { cols: project.id === _vm.myProject.id ? 7 : 12 },
                    },
                    [
                      _c("p", { staticClass: "name-contact mb-0" }, [
                        _c("strong", [_vm._v(_vm._s(project.titre))]),
                      ]),
                    ]
                  ),
                  project.id === _vm.myProject.id
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0 pl-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "container-action-list-contact w-100",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "float-right" },
                                [
                                  _vm.canEditProject
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "mb-2": _vm.$screen.width <= 334,
                                            "float-right": !_vm.canEditProject,
                                          },
                                          attrs: { variant: "light" },
                                          on: { click: _vm.handleClickEdit },
                                        },
                                        [
                                          _c("Edit", {
                                            attrs: {
                                              color: "#06263E",
                                              size: 16,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "light" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickCopy(project)
                                        },
                                      },
                                    },
                                    [
                                      _c("clipboard-copy", {
                                        attrs: { color: "#06263E", size: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }