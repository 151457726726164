var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      [
        _vm.$screen.width <= 567
          ? _c(
              "div",
              [
                _c(
                  "h1",
                  {
                    class: [
                      `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                      { "is-pwa": _vm.$isPwa() },
                    ],
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.getTitle()) + "\n\t\t\t")]
                ),
                _c("list-project-mobile", {
                  attrs: {
                    projects: _vm.myProjects,
                    "current-project": _vm.myProject,
                  },
                  on: {
                    "list-project-mobile:change-project":
                      _vm.changeProjectAction,
                    "list-project-mobile:edit-project": _vm.openProjectWindow,
                    "list-project-mobile:copy-invoice": _vm.copyInvoiceDetails,
                    "list-project-mobile:add-project": function ($event) {
                      _vm.modalShowCreate = true
                    },
                  },
                }),
              ],
              1
            )
          : _c("div", [
              _c(
                "div",
                { staticClass: "animated fadeIn" },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "center" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: !_vm.isFilmSingle ? 12 : 8 } },
                                [
                                  _c(
                                    "h1",
                                    {
                                      class: [
                                        `${
                                          !_vm.$screen.sm
                                            ? "main-page-title"
                                            : ""
                                        }`,
                                        { "is-pwa": _vm.$isPwa() },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.getTitle()) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.isFilmSingle && !_vm.isFilmSingleFree
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          on: {
                                            click: function ($event) {
                                              _vm.modalShowCreate = true
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-plus",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.FormMSG(62, "Add project")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isFilmSingle && _vm.isFilmSingleFree
                                ? _c("b-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "float-right" },
                                      [
                                        _c("subscription-dialog", {
                                          attrs: {
                                            variantBtn: "primary",
                                            icon: "fa fa-plus",
                                            "btn-label": _vm.FormMSG(
                                              62,
                                              "Add project"
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-card", [
                                    _c("h2", [_vm._v(_vm._s(_vm.getHeader()))]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row justify-content-start align-items-center",
                                      },
                                      [
                                        _c(
                                          "b-list-group",
                                          { staticClass: "w-100" },
                                          _vm._l(
                                            _vm.myProjects,
                                            function (project) {
                                              return _c(
                                                "b-list-group-item",
                                                {
                                                  key: project.id,
                                                  attrs: {
                                                    action: "",
                                                    href: "#",
                                                    active:
                                                      _vm.myProject &&
                                                      _vm.myProject.id ===
                                                        project.id,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeProjectAction(
                                                        project
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "mb-0 mt-3 pb-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            project.titre
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        { attrs: { xl: "6" } },
                                                        [
                                                          _c("p", {}, [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  project.production
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { xl: "6" } },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "float-right",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "-18px",
                                                              },
                                                            },
                                                            [
                                                              _vm.myProject &&
                                                              _vm.myProject
                                                                .id ===
                                                                project.id
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "mr-3 btn-md hidden-sm",
                                                                      attrs: {
                                                                        variant:
                                                                          "outline-light",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.copyInvoiceDetails(
                                                                              project.invoiceDetails
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "icon-envelope-letter",
                                                                      }),
                                                                      _vm._v(
                                                                        "  " +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              23,
                                                                              "Copy invoice details to clipboard..."
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.myProject &&
                                                              _vm.myProject
                                                                .id ===
                                                                project.id &&
                                                              _vm.canEditProject()
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn-md",
                                                                      attrs: {
                                                                        variant:
                                                                          "outline-light",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openProjectWindow,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-start align-items-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-edit",
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "1.15rem",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-top":
                                                                                    "0px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    52,
                                                                                    "Edit"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
        _c(
          "b-modal",
          {
            attrs: {
              size: "xl",
              "header-class": "header-class-modal-doc-package",
              title: _vm.FormMSG(55, "Edit project"),
              "ok-title": _vm.FormMSG(51, "Save"),
              "cancel-title": _vm.FormMSG(53, "Cancel"),
            },
            on: {
              ok: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmitModalEditProject.apply(null, arguments)
              },
              cancel: function ($event) {
                $event.preventDefault()
                return _vm.handleCloseModalEditProject.apply(null, arguments)
              },
            },
            model: {
              value: _vm.modalShowEdit,
              callback: function ($$v) {
                _vm.modalShowEdit = $$v
              },
              expression: "modalShowEdit",
            },
          },
          [
            _c("ProjectForm", {
              attrs: { "is-modal": true, "can-update": _vm.canUpdate },
              on: { updatefinishevent: _vm.onUpdateProjectFinished },
            }),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            attrs: {
              size: "xl",
              "header-class": "header-class-modal-doc-package",
              title: _vm.FormMSG(56, "Add project"),
              "ok-title": _vm.FormMSG(54, "Add"),
              "cancel-title": _vm.FormMSG(53, "Cancel"),
              "hide-header-close": "",
              "no-close-on-backdrop": "",
              "no-close-on-esc": "",
            },
            on: {
              ok: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmitModalCreateProject.apply(null, arguments)
              },
              cancel: function ($event) {
                $event.preventDefault()
                return _vm.handleCloseModalCreateProtect.apply(null, arguments)
              },
            },
            model: {
              value: _vm.modalShowCreate,
              callback: function ($$v) {
                _vm.modalShowCreate = $$v
              },
              expression: "modalShowCreate",
            },
          },
          [
            _c("ProjectForm", {
              attrs: {
                "project-form-id": "0",
                "is-create-with-modal": true,
                "is-modal": true,
                "can-create": _vm.canCreate,
                "license-id": _vm.licenseId,
              },
              on: { createfinishevent: _vm.onCreateProjectFinished },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }