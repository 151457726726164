<template>
	<div class="container-mobile animated fadeIn">
		<div class="form content-contact-mobile">
			<b-row v-if="isFilmSingle && !isFilmSingleFree" class="mb-3">
				<b-col>
					<div class="float-right">
						<b-button variant="light" @click="handleClickAddProject">
							{{ FormMSG(21, 'Add project') }}
							<plus-circle color="#06263E" :size="16" />
						</b-button>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="isFilmSingle && isFilmSingleFree" class="mb-3">
				<b-col>
					<div class="float-right">
						<subscription-dialog variantBtn="light" icon="fa fa-plus" :btn-label="FormMSG(21, 'Add project')" />
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="cols-12">
					<b-input-group>
						<b-form-input type="text" v-model="filter" :placeholder="FormMSG(1, 'Type to search ...')" @input="handleInputFilter" />
						<b-input-group-append>
							<b-input-group-text>
								<search color="#06263E" :size="16" class="icon" v-if="filter.length === 0" />
								<x color="#06263E" :size="16" class="icon" @click="clearFilter" v-else />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<div class="title-list">
						<h2>{{ title }}</h2>
					</div>
				</b-col>
			</b-row>
			<div v-if="listProjects.length === 0" class="text-center">
				<p>{{ FormMSG(3, 'No result') }}</p>
			</div>
			<div
				v-for="(project, index) in listProjects"
				:key="index"
				class="cursor-pointer"
				:class="{ active: project.id === myProject.id }"
				@click="clickRow(project)"
			>
				<div v-if="index > 0" class="divider-line" />
				<b-row class="pt-3 pb-3" :class="{ 'mt-3': index === 0 }">
					<b-col :cols="project.id === myProject.id ? 7 : 12" class="pr-0">
						<p class="name-contact mb-0">
							<strong>{{ project.titre }}</strong>
						</p>
					</b-col>
					<b-col v-if="project.id === myProject.id" cols="5" class="pr-0 pl-0">
						<div class="container-action-list-contact w-100">
							<div class="float-right">
								<b-button
									v-if="canEditProject"
									variant="light"
									class="mr-2"
									:class="{
										'mb-2': $screen.width <= 334,
										'float-right': !canEditProject
									}"
									@click="handleClickEdit"
								>
									<Edit color="#06263E" :size="16" />
								</b-button>
								<b-button variant="light" @click="handleClickCopy(project)">
									<clipboard-copy color="#06263E" :size="16" />
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { Search, XCircle, ClipboardCopy, Edit, PlusCircle, X } from 'lucide-vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { store } from '@/store';
import GlobalMixin from '@/mixins/global.mixin';
import SubscriptionDialog from '@/components/InfoSheet/SubscriptionDialog.vue';

export default {
	name: 'ListProjectMobile',

	components: {
		Search,
		XCircle,
		ClipboardCopy,
		Edit,
		PlusCircle,
		SubscriptionDialog,
		X
	},

	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	props: {
		projects: { type: Array, default: () => [] },
		currentProject: { type: Object, default: () => ({}) }
	},

	data() {
		return {
			filter: '',
			listProjects: [],
			originalListProjects: [],
			myProject: {}
		};
	},

	computed: {
		canEditProject() {
			// instead of isFilmSingle
			if (store.isFilmSingleUserFree() || store.isFilmSingleUser()) {
				return true;
			} else {
				return false;
			}
		},
		title() {
			if (store.isPme()) {
				return this.FormMSG(110, 'Offices');
			}
			return this.FormMSG(10, 'Projects');
		}
	},

	watch: {
		projects: {
			handler(val) {
				this.listProjects = val;
				this.originalListProjects = val;
			},
			immediate: true,
			deep: true
		},
		currentProject: {
			handler(val) {
				this.myProject = val;
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		handleInputFilter(payload) {
			const filter = payload.trim().toLowerCase();
			this.listProjects = this.originalListProjects.filter((oLP) => {
				if (oLP.titre.toLowerCase().indexOf(filter) > -1) {
					return true;
				}
				return false;
			});
		},
		handleClickAddProject() {
			this.$emit('list-project-mobile:add-project');
		},
		async handleClickCopy(project) {
			const action = async () => {
				this.$emit('list-project-mobile:copy-invoice', project.invoiceDetails);
				this.createToastForMobile(this.FormMSG(22, 'invoice details copied successfully'), '', '');
			};
			this.confirmModal(action, this.FormMSG(23, 'Copy invoice details to clipboard ?'));
		},
		handleClickEdit() {
			this.$emit('list-project-mobile:edit-project');
		},
		clickRow(project) {
			this.$emit('list-project-mobile:change-project', project);
		},
		clearFilter() {
			if (this.filter !== '') {
				this.filter = '';
				this.listProjects = this.projects;
			}
		}
	}
};
</script>

<style scoped></style>
